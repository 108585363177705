import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import CountDown from '../CountDown';

const PrizeNoModal = ({ toggle, isOpen, modalTitle, description, tryAgin, buttonName, PrimaryAction, prizeImage }) => {
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className='prize-modal no-win' centered={true} size={'lg'} fullscreen={'md'}>
                <ModalBody className='p-0'>
                    <div className='prize-visual'>
                        <img src={prizeImage} alt='Prize-image' />
                    </div>
                    <div className='pt-80 text-center'>
                        <div className='modal-header'>{modalTitle}</div>
                        <div className='pt-80'>
                            <p>{description}</p>
                            {tryAgin ?
                                (<p className='pt-4 mt-3'>{tryAgin}</p>)
                                : ('')
                            }
                        </div>
                    </div>
                    <div className='m-0'>
                        <div className='d-flex w-100 justify-content-center'>
                            <PrimaryButton btnName={buttonName} ClassName="noprize-btn" btnType={'button'} onClick={PrimaryAction} />
                        </div>
                        <div className='text-center'>
                            <CountDown countText={'You will be directed to the home screen in'} navPath={'/'} />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default PrizeNoModal;
import React from 'react'

const bufferToBase64 = (buffer) => {
    const arrayBufferToBase64 = () => {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    return arrayBufferToBase64();
}

export default bufferToBase64

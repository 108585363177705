import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Registration from './pages/Registration';
import EmailVerification from './pages/EmailVerification';
import GameSpin from './pages/GameSpin';
import ThankYou from './pages/ThankYou';
import { useEffect, useState } from 'react';
import ProtectedRoutes from './ProtectedRoutes'
import axios from 'axios';
import { apiURL } from './helpers/URL';
import loaderImg from '../src/assets/images/loading.svg'
import { campaignContext, prizeContext, spinContext } from './constant/Context';
import AddsLoad from './pages/AddsLoad';
import CheckIdle from './constant/CheckIdle';
import homePageImage from './assets/images/homepage.jpg'
import 'react-loading-skeleton/dist/skeleton.css';
import bufferToBase64 from './constant/bufferToBase64';
import getMimeType from './constant/getMimeType';
import { Toaster } from 'react-hot-toast';
import { segmentTemplateFoure, segmentTemplateOne, segmentTemplateThree, segmentTemplateTwo } from './constant/SpinWheelSegment';

function App() {

  const [campaignData, setCampaignData] = useState({});
  const [settingsData, setSettingsData] = useState(null);
  const [adds, setAdds] = useState([]);
  const [loader, setLoader] = useState({
    settingsLoader: false,
    addsLoader: false,
  })
  const [spinImage, setSpinImage] = useState();
  const [prizesData, setprizesData] = useState([]);

  const compaingnStart = async () => {
    try {
      const firstLoader = { ...loader, settingsLoader: true };
      setLoader(firstLoader);
      const response = await axios.get(`${apiURL}/adminpanel/settings`);
      if (
        response?.data?.settings &&
        response?.data?.settings?.length > 0
      ) {
        setSettingsData(response?.data?.settings[0]);
      } else {
        setSettingsData(null);
      }
      const secondLoader = { ...loader, settingsLoader: false };
      setLoader(secondLoader);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getCampaignData = async () => {
    try {
      const response = await axios.get(`${apiURL}/adminpanel/campaign`);
      if (response?.data?.status?.toLowerCase() === 'success') {
        if (response?.data?.data && response?.data?.data?.length > 0) {
          const campaign = response?.data?.data?.filter((item) => item?.status === "ACTIVE")
          const result = campaign && campaign?.length > 0 ? campaign[0] : {};
          setCampaignData(result);
          if (result?._id) {
            getPrizes(result?._id);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getCampaignData();
    compaingnStart();
  }, []);

  useEffect(() => {
    const getAdds = async () => {
      const response = await axios.get(`${apiURL}/adminpanel/ads/get-all-active`);
      if (response?.data?.status?.toLowerCase() === 'success') {
        for (let index = 0; index < response?.data?.images.length; index++) {
          const buffer = await axios.post(`${apiURL}/adminpanel/ads/read-file`, { imageKey: response?.data?.images[index].image });
          const base64 = bufferToBase64(buffer?.data?.data?.buffer?.data)

          if (response.data.images[index].fileType === 'video') {
            response.data.images[index].image = `data:video/mp4;base64,${base64}`;
          } else {
            response.data.images[index].image = `data:${getMimeType(base64)};base64,${base64}`;
          }
        }
        
        const result = response?.data?.images && response?.data?.images?.length > 0 ? response?.data?.images : [];
        setAdds(result);
      }
      const secondLoader = { ...loader, addsLoader: false };
      setLoader(secondLoader);
    }
    if (settingsData?.isAdsLive) {
      const firstLoader = { ...loader, addsLoader: true };
      setLoader(firstLoader);
      getAdds();
    }
  }, [settingsData]);

  const getPrizes = async (id) => {
    const response = await axios.post(`${apiURL}/adminpanel/prize`, { campaign: id });
    for (let index = 0; index < response?.data?.data?.prizes?.length; index++) {
      const buffer = await axios.post(`${apiURL}/adminpanel/ads/read-file`, { imageKey: response?.data?.data?.prizes[index]?.prizeImg });
      const base64 = bufferToBase64(buffer?.data?.data?.buffer?.data)
      response['data']['data']['prizes'][index]["prizeImg"] = `data:${getMimeType(base64)};base64,${base64}`
    }
    const result = response?.data?.data?.prizes && response?.data?.data?.prizes?.length > 0 ? response?.data?.data?.prizes : [];
    setprizesData(result)
    if (response?.data?.data?.prizes?.length > 0) {
      setSpinner(response?.data?.data?.prizes, result);
    }
  }

  const setSpinner = (prizes, result) => {
    if (prizes?.length === 4) {
      setSpinImage({ path: segmentTemplateOne, value: result })
    } else if (prizes?.length === 6) {
      setSpinImage({ path: segmentTemplateTwo, value: result })
    } else if (prizes?.length === 8) {
      setSpinImage({ path: segmentTemplateThree, value: result })
    } else if (prizes?.length === 10) {
      setSpinImage({ path: segmentTemplateFoure, value: result })
    }
  }

  if (loader?.addsLoader || loader?.settingsLoader) {
    return (
      <div className="loader-container">
        <img src={loaderImg} alt="Loading..." className="loader" />
      </div>
    );
  }

  return (
    <campaignContext.Provider value={[campaignData, setCampaignData]}>
      <prizeContext.Provider value={[prizesData, setprizesData]}>
        <spinContext.Provider value={[spinImage, setSpinImage]}>
          <div className="App">
            <Routes>
              {
                settingsData?.isCampaignLive &&
                <>
                  {settingsData?.isAdsLive && adds?.length > 0 && campaignData?.status !== "ACTIVE" &&
                    < Route path='/' element={<AddsLoad adds={adds} />} />
                  }
                  {settingsData?.isAdsLive && adds?.length > 0 && campaignData?.status === "ACTIVE" &&
                    <Route path="/" element={<CheckIdle baseElement={<Home homePageImage={homePageImage} />} idleElement={<AddsLoad adds={adds} />} />} />
                  }
                  {!settingsData?.isAdsLive &&
                    <Route path="/" element={<Home homePageImage={homePageImage} />} />
                  }
                  <Route path="/registration" element={<Registration />} />
                  <Route path="/thank-you" element={<ThankYou />} />
                  <Route element={<ProtectedRoutes />}>
                    <Route path="/email-verification" element={<EmailVerification />} />
                    <Route path="/game-spin" element={<GameSpin />} />
                  </Route>
                </>
              }
              {
                settingsData?.isAdsLive && !settingsData?.isCampaignLive && adds?.length > 0 && campaignData?.status !== "ACTIVE" &&
                < Route path='/' element={<AddsLoad adds={adds} />} />
              }
              {
                !settingsData?.isAdsLive && !settingsData?.isCampaignLive &&
                <Route path='/' element={<ThankYou />} />
              }
            </Routes>
            <Toaster position="top-center" reverseOrder={false} toastOptions={{
              success: {
                iconTheme: {
                  primary: '#fff',
                  secondary: '#34c38f',
                },
                style: {
                  background: '#34c38f',
                  color: '#fff',
                },

              },
              error: {
                iconTheme: {
                  primary: 'white',
                  secondary: '#f46a6a',
                },
                style: {
                  background: '#f46a6a',
                  color: '#fff',
                },
              },
            }} />
          </div>
        </spinContext.Provider>
      </prizeContext.Provider>
    </campaignContext.Provider>
  );
}

export default App;

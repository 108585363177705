import React, { useContext, useEffect, useState } from 'react';
import TotalSpinCount from '../components/TotalSpinCount';
import SpinWheel from '../components/SpinWheel';
import ScanQrCode from '../components/ScanQrCode';
import { apiURL } from '../helpers/URL';
import axios from 'axios';
import { spinTIme } from '../constant/Countdown';
import { campaignContext } from '../constant/Context';

const GameSpin = () => {

    const[campaignData, setCampaignData]= useContext(campaignContext);

    const [totalCount, setTotalCount] = useState({ spinCount: 0 })
    const [childComponent, setChildComponent] = useState();
    const [loading, setLoading] = useState(1);

    const [countdown, setCountdown] = useState(spinTIme);
    const [intervaltime, setIntervaltime] = useState();
    const [timerflag, setTimerflag] = useState('start');

    const decodedEmail = localStorage.getItem("reg-site");
    const userEmail = atob(decodedEmail);

    const getSpinCount = async () => {
        try {
            const data = {}
            data['email'] = userEmail
            data['campaign'] = campaignData?._id
            const response = await axios.post(`${apiURL}/adminpanel/qrcode/spin-count`, data);
            setTotalCount(response?.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getSpinCount();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
        setIntervaltime(interval)

        return () => clearInterval(interval); // Cleanup interval on component unmount

    }, [timerflag]);

    const clearIntervalMain = () => {
        setCountdown(spinTIme)
        setTimerflag("end")
        clearInterval(intervaltime);

    }

    const startInterval = () => {
        setCountdown(spinTIme)
        setTimerflag("start");
    }
    
    useEffect(() => {
        setChildComponent(() => {

            return (
                <>
                    {loading == 1 ? <>
                        <TotalSpinCount spinTotal={totalCount} />
                        <ScanQrCode spinTotal={totalCount} clearIntervalMain={clearIntervalMain} countdown={countdown} setCountdown={setCountdown} startInterval={startInterval} timerflag={timerflag} setResetQrCount={setTotalCount} />
                        <SpinWheel spinTotal={totalCount} clearIntervalMain={clearIntervalMain} countdown={countdown} setCountdown={setCountdown} startInterval={startInterval} timerflag={timerflag} setResetQrCount={setTotalCount} />
                    </> : ""}
                </>
            )
        })
    }, [totalCount?.spinCount, countdown])

    return (
        <>
            <div className='page-container'>
                <div className='gamespin-screen'>
                    {childComponent}
                </div>
            </div>
        </>
    );
};

export default GameSpin;
import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
import PrimaryButton from '../components/Buttons/PrimaryButton';
import warningIcon from '../assets/images/warning-icon.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SecountryBtn from '../components/Buttons/SecountryBtn';
import { useEffect } from 'react';
import { useState } from 'react';
import { registrationTime } from '../constant/Countdown';
import toast from "react-hot-toast"
import { campaignContext } from '../constant/Context';

const Registration = () => {

    const [campaignData, setCampaignData] = useContext(campaignContext);

    const navigate = useNavigate();
    const targetPage = "/";

    const apiURL = process.env.REACT_APP_BACKEND_URL;
    const [countdown, setCountdown] = useState(registrationTime);
    const [intervaltime, setIntervaltime] = useState();
    const [timerflag, setTimerflag] = useState('start');
    const verificationPath = () => {
        navigate('/email-verification');
    }
    const backToHome = () => {
        navigate("/");
    };

    const regForm = {
        email: "",
        receiveMarketEmail: true
    };

    const regFormSchema = Yup.object().shape({
        email: Yup.string().email('Enter valid email address').required("Enter email address").trim(),
        // receiveMarketEmail: Yup.bool().oneOf([true]),
    });

    const { register, getValues, reset, handleSubmit, formState: { errors, } } = useForm({
        resolver: yupResolver(regFormSchema),
        defaultValues: regForm,
        mode: "onChange"
    });

    const onSubmit = async (values) => {
        try {
            // Encode email
            const encodedEmail = btoa(values?.email);
            localStorage.setItem("reg-site", encodedEmail);
            const response = await axios.post(`${apiURL}/adminpanel/subscription/check`, { ...values, campaign: campaignData?._id });
            if (response?.data?.status?.toLowerCase() === "success") {
                reset();
                if (response?.data?.data?.verified === true) {
                    navigate('/game-spin');
                } else {
                    verificationPath();
                }
            } else {
                toast.error(response?.data?.message)
            }
        } catch (error) {
            console.error('Error:', error);
        }

    };

    //let interval = 0;
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
        setIntervaltime(interval);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [timerflag]);

    useEffect(() => {
        // Redirect when countdown reaches 0
        if (countdown === 0) {
            window.location.href = targetPage;
        }
    }, [countdown, targetPage]);
    const resetTimer = () => {
        setCountdown(registrationTime);
        if (getValues('email').length === 0) {
            setTimerflag('start')
        } else {
            setTimerflag('end')
            clearInterval(intervaltime)
        }

    }

    return (
        <div>
            <div className='page-container'>
                <div className='register-screen'>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                        <h5 className='form-title'>Please provide an email for prize collection</h5>
                        <label htmlFor='email' className='w-100'>
                            <div className='check-email'>
                                <input type="email" id='email' onKeyUp={resetTimer} {...register("email")} placeholder='Enter your email address..' className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}

                            </div>
                        </label>

                        <div className='d-flex gap-5 pt-4 mt-1'>
                            <div>
                                <img src={warningIcon} alt='Wa rningIcon' />
                            </div>
                            <div className='warning-content'>
                                <p>We will send an email notification to all winners. Winners are required to present the email during prize collection hence please ensure you provide a valid email address for this purpose.</p>
                            </div>
                        </div>
                        <div className='form-footer'>
                            <div className="form-group">
                                <input type="checkbox" defaultChecked={true} {...register("receiveMarketEmail")} id="receiveMarketEmail" />
                                <label htmlFor="receiveMarketEmail"><span> Receive marketing emails for the latest events and offers.</span></label>
                            </div>
                            <div>
                                <PrimaryButton disabled={getValues('email').length < 1 ? true : false} btnName={'Continue'} ClassName="" btnType={'submit'} />
                            </div>
                            <div className='mt-4'>
                                <SecountryBtn
                                    btnName={"Back to Home"}
                                    ClassName=""
                                    btnType={"button"}
                                    onClick={backToHome}
                                />

                            </div>
                            {timerflag === 'start' && <div className='mt-3' >
                                <p className="text-center">
                                    You will be directed to the home screen in <b>00 : {countdown}</b>
                                </p>

                            </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Registration;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { prizeModalCountdown } from '../constant/Countdown';

const CountDown = ({ countText, navPath, modalClose }) => {

    const navigate = useNavigate();

    const [countdown, setCountdown] = useState(prizeModalCountdown);

    const navigatePath = () => {
        if (navPath) {
            navigate(`${navPath}`)
        } else (
            modalClose()
        )
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount

    }, []);

    useEffect(() => {

        if (countdown === 0) {
            navigatePath()
            // window.location.href = targetPage;
        }

    }, [countdown]);
    return (
        <>
            <div className='text-center'>
                <p className='m-0 count-text text-center'>{countText} 00:{countdown}</p>
            </div>
        </>
    );
};

export default CountDown;
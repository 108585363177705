import React from 'react';
import placeholder from '../assets/images/thumbnail.png'
import { Link } from 'react-router-dom';
import facebook from '../assets/images/facebook.svg';
import instagran from '../assets/images/instagram.svg';
import twitter from '../assets/images/twitter.svg';
import campaignEnd from '../assets/images/campaign-ended.jpg'

const ThankYou = () => {
    return (
        <>
            <div className='page-container p-0'>
                <div className='home-page-img position-fixed'>

                    <img src={`${campaignEnd}`} alt='sample-image' />
                </div>
                {/* <div className='home-img'>
                    <img src={`${placeholder}`} alt='sample-image' />
                </div> */}
                {/* <div className='text-center'>
                    <p className='compain-txt'>Campaign has ended.<br />
                        Thank you to all participants!</p>
                    <div className='more-grp'>
                        <h2>FOLLOW US FOR MORE<br /> UPCOMING PROMOS</h2>
                        <div className='social-link'>
                            <ul className='d-flex justify-content-between'>
                                <li>
                                    <Link to="#" ><img src={facebook} alt='Facebook' />Home360 Malaysia</Link>
                                </li>
                                <li>
                                    <Link to="#" ><img src={instagran} alt='Instagram' />home360my</Link>
                                </li>
                                <li>
                                    <Link to="#" ><img src={twitter} alt='Twitter' />@Home360Malaysia</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default ThankYou;
import React from 'react';

const TotalSpinCount = ({ spinTotal }) => {
    return (
        <>
            <div className='text-center'>
                <div className='modal-header'>YOU HAVE <span className='spin-count'> {spinTotal?.spinCount} </span> SPIN(S)</div>
                <p className='runing-text spin-text'>Scan QR ticket(s) to add spinning chance.</p>
            </div>
        </>
    );
};

export default TotalSpinCount;
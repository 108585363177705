import React from 'react'

const getMimeType = (b64) => {
    try {
        const signatures = {
            JVBERi0: "application/pdf",
            R0lGODdh: "image/gif",
            R0lGODlh: "image/gif",
            iVBORw0KGgo: "image/png",
            "/9j/": "image/jpg",
            Qk02U: "image/bmp",
            UklGR: "image/webp",
            RIFF: "image/webp", // Another common WebP signature
            BM: "image/bmp",
            PNG: "image/png",
            GIF: "image/gif",
            JFIF: "image/jpeg", // Another JPEG signature
            Exif: "image/jpeg", // Another JPEG signature
            "8BPS": "image/x-photoshop", // Photoshop Document
            IHDR: "image/png", // PNG header
        };

        for (let s in signatures) {
            if (b64.indexOf(s) === 0) {
                return signatures[s];
            }
        }
    } catch (error) {
        return false;
    }
}

export default getMimeType

import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { apiURL } from '../helpers/URL';
import PrimaryButton from './Buttons/PrimaryButton';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import arrowGif from '../assets/lottie/arrow.gif'
import closeIcon from "./../assets/images/close.svg";
import QrReader from "react-qr-reader";
import ScanQrNotifyModal from './Modals/ScanQrNotifyModal';
import animationSuccess from '../assets/lottie/animation-success.json';
import animationFail from '../assets/lottie/animation-fail.json';
import { useNavigate } from 'react-router-dom';
import SecountryBtn from './Buttons/SecountryBtn';
import CountDown from './CountDown';
import { campaignContext } from '../constant/Context';

const ScanQrCode = ({ spinTotal, setResetQrCount, loading, setCountdown, countdown, clearIntervalMain, startInterval }) => {
    const navigate = useNavigate();

    const [campaignData, setCampaignData] = useContext(campaignContext)

    const [openScanModal, setOpenScanModal] = useState(false);
    const [qrScanData, setQrScanData] = useState();
    const [scanQrNotifyModal, setScanQrNotifyModal] = useState(false);

    const decodedEmail = localStorage.getItem("reg-site");
    const userEmail = atob(decodedEmail);

    const handleError = (err) => {
        console.error(err);
    };

    const toggleScanModal = () => {
        clearIntervalMain();
        setOpenScanModal(!openScanModal);
    };

    const closeScanModal = () => {
        startInterval();
        setOpenScanModal(false);
    };

    //Scan Notification Modal
    const scanNotifiyToggle = () => {
        setScanQrNotifyModal(!scanQrNotifyModal)
    };
    const closeNotification = () => {
        startInterval();
        setScanQrNotifyModal(false);
    }

    const addMoreQRToggle = () => {
        setScanQrNotifyModal(false)
        toggleScanModal();
    }


    const onSubmitQrData = async (qrCode) => {
        try {
            if (qrCode) {
                toggleScanModal()
                const data = {}
                data['email'] = userEmail
                data['qr'] = qrCode
                data['campaign'] = campaignData?._id
                const response = await axios.post(`${apiURL}/adminpanel/qrcode/scan`, data);
                setQrScanData(response?.data);
                if (response?.data?.status?.toLowerCase() === "success") {
                    setResetQrCount(response?.data);
                    navigate('/game-spin');
                    scanNotifiyToggle();
                } else {
                    scanNotifiyToggle();
                }
            }

            // setQrScanData(response?.data);
        } catch (error) {
            console.error('Error:', error);
        }

    }
    return (
        <>
            <div className='text-center'>
                <div className='d-flex align-items-center justify-content-center'>
                    {spinTotal?.spinCount === 0 && (
                        <>
                            <div className='arrow'>
                                <img src={arrowGif} alt='arrowGif' />
                            </div>
                            <div className='text-center'>
                                <PrimaryButton btnName={'Scan QR Code'} ClassName="" btnType={'button'} onClick={toggleScanModal} />

                            </div>
                            <div className='arrow ar-right'>
                                <img src={arrowGif} alt='arrowGif' />
                            </div>
                        </>
                    )}
                    {spinTotal?.spinCount > 0 && (
                        <div className='text-center'>
                            <SecountryBtn btnName={'+ Add More'} ClassName="" btnType={'button'} onClick={toggleScanModal} />
                        </div>
                    )}

                </div>
            </div>
            {openScanModal &&
                <Modal isOpen={true} className='verify-modal spin-modal' toggle={toggleScanModal} centered={true} size={'lg'} fullscreen={'md'}>
                    <ModalHeader>
                        <div className='close-btn' onClick={closeScanModal}>
                            <img src={closeIcon} alt='closeIcon' />
                        </div>
                    </ModalHeader>
                    <ModalBody className='h-auto'>
                        <div className='text-center verifi-content'>
                            <div className='scan-view'>

                                {openScanModal && (
                                    <>
                                        <QrReader
                                            delay={400}
                                            onError={handleError}
                                            onScan={onSubmitQrData}
                                            // chooseDeviceId={() => selected}
                                            style={{ width: "100%" }}
                                        />
                                    </>
                                )}
                                {/* {loadingScan && <p>Loading</p>} */}

                            </div>

                            <div className='pt-80'>
                                <h5 className='form-title pb-2 mb-1'>Scan the QR tickets</h5>
                                <p className='w-100'> Scan the QR ticket using the scanner in below to add a spinning chance. Only unused QR tickets will be accepted.</p>
                            </div>
                            <div className='d-flex justify-content-center pt-80'>
                                <div className='down-arrow'>
                                    <img src={arrowGif} alt='arrowGif' />
                                </div>
                            </div>
                            <CountDown countText={'Window will auto close in'} modalClose={closeScanModal} />
                        </div>

                    </ModalBody>

                </Modal>
            }
            {scanQrNotifyModal && <ScanQrNotifyModal
                toggle={scanNotifiyToggle}
                isOpen={scanQrNotifyModal}
                lottiefiles={qrScanData?.status === "SUCCESS" ? animationSuccess : animationFail}
                modalTitle={`${qrScanData?.status === "SUCCESS" ? `${`You have acquired 1 spin`}` : 'Invalid QR code'}`}
                description={qrScanData?.status === "SUCCESS" ? `${`You have ${qrScanData?.spinCount} spin(s) currently. Add more spinning chance by scanning more unused QR tickets.`}` : 'This QR ticket is invalid or has been used. Please try again with another ticket. Contact counter staff if you are still facing issues with your QR ticket.'}
                buttonName={qrScanData?.status === "SUCCESS" ? '+ Add More' : 'Try Another Ticket'}
                PrimaryAction={addMoreQRToggle}
                closeBtn={qrScanData?.status === "SUCCESS" ? 'Close' : 'Go Back'}
                closeToggle={closeNotification}
                btnPosition={qrScanData?.status === "SUCCESS" ? 'flex-column-reverse no-gap' : 'flex-column-reverse qr-invalid'}
            />
            }
        </>
    );
};

export default ScanQrCode;
import { useEffect, useState } from 'react'

const CheckIdle = ({ baseElement, idleElement }) => {

    const [isIdle, setIsIdle] = useState(null);

    const checkInactivity = () => {
        const expiryTime = localStorage.getItem('expireTime');
        if (expiryTime && Number(expiryTime) < Date.now()) {
            setIsIdle(idleElement);
        } else {
            setIsIdle(baseElement);
        }
    }

    const updateExpireTime = () => {
        const expireTime = Date.now() + (2 * 60000);
        localStorage.setItem('expireTime', expireTime?.toString());
        setIsIdle(baseElement);
    }

    useEffect(() => {
        setIsIdle(baseElement);

        const interval = setInterval(() => {
            checkInactivity();
        }, 5000);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        updateExpireTime();

        window.addEventListener('click', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);

        return () => {
            window.removeEventListener('click', updateExpireTime);
            window.removeEventListener('keypress', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
            window.removeEventListener('mousemove', updateExpireTime);
        }
    }, [])

    return (
        <>{isIdle}</>
    )
}

export default CheckIdle
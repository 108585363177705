import React, { useEffect, useState } from 'react'
import CustomSlider from '../components/customslider/CustomSlider';
import VideoSlide from '../components/customslider/VideoSlide';
import ImageSlide from '../components/customslider/ImageSlide';

const AddsLoad = ({ adds }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <>
            <CustomSlider
                slides={adds?.map((item, index) => (
                    <div key={`adds-item-${index}`}> 
                        {item?.fileType === "video" ? (
                            <VideoSlide index={index} path={item.image} setCurrentSlide={setCurrentSlide} currentSlide={currentSlide} adds={adds} />
                        ) : (
                            <ImageSlide path={item.image} setCurrentSlide={setCurrentSlide} currentSlide={currentSlide} adds={adds} />
                        )}
                    </div>
                ))}
                currentSlide={currentSlide}
                adds={adds}
                setCurrentSlide={setCurrentSlide}
            />
        </>
    )
}

export default AddsLoad
export const prizeModalCountdown = 40;

export const scanQrCountdown = prizeModalCountdown;

export const spinLaterCountdown = prizeModalCountdown;

export const emailVerificationCountdown = 80;

export const spinTIme = 10;

export const registrationTime = 45;
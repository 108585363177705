import React, { memo } from 'react';

const SecountryBtn = ({ btnType, btnName, btnIcon, ClassName, onClick }) => {
    return (
        <>
            <button className={`secountry-btn common-style ${ClassName}`} type={btnType} onClick={onClick}>
                {btnIcon ? <img src={btnIcon} alt='btnicon' /> : ''}
                {btnName}
            </button>
        </>
    );
};

export default memo(SecountryBtn);
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import { Player } from '@lottiefiles/react-lottie-player';
import SecountryBtn from '../Buttons/SecountryBtn';
import CountDown from '../CountDown';
import { scanQrCountdown } from '../../constant/Countdown';

const ScanQrNotifyModal = ({ toggle, isOpen, modalTitle, lottiefiles, description, buttonName, PrimaryAction, closeToggle, closeBtn, btnPosition }) => {
    const [countdown, setCountdown] = useState(scanQrCountdown);
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount

    }, []);

    useEffect(() => {

        if (countdown === 0) {
            closeToggle()
        }

    }, [countdown]);
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className='prize-modal no-win' centered={true} size={'lg'} fullscreen={'md'}>
                <ModalBody>
                    <div className='text-center verifi-content'>
                        <div className='lottiefiles'>
                            <Player
                                src={lottiefiles}
                                className="player"
                                loop={true}
                                autoplay
                            />
                        </div>
                        <h5 className='form-title pb-2 mb-1'>{modalTitle}</h5>
                        <p>{description}</p>
                        <div className={`d-flex gx-5 gap-4 w-100 align-items-center ${btnPosition}`}>
                            {closeBtn ? <SecountryBtn btnName={closeBtn} ClassName="" btnType={'button'} onClick={closeToggle} /> : ''}

                            <PrimaryButton btnName={buttonName} ClassName="" btnType={'button'} onClick={PrimaryAction} />
                        </div>
                        <div className='text-center'>
                            <p className='m-0 count-text text-center'>{'Window will auto close in'} 00:{countdown}</p>
                        </div>

                    </div>

                </ModalBody>
            </Modal>
        </>
    );
};

export default ScanQrNotifyModal;
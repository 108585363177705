//Template One
import tempOne_A from '../assets/images/wheels/template-one/temp-1.png';
import tempOne_B from '../assets/images/wheels/template-one/temp-2.png';
import tempOne_C from '../assets/images/wheels/template-one/temp-3.png';
import tempOne_D from '../assets/images/wheels/template-one/temp-4.png';

//Template Two
import tempTwo_A from '../assets/images/wheels/template-two/temp-1a.png';
import tempTwo_B from '../assets/images/wheels/template-two/temp-2a.png';
import tempTwo_C from '../assets/images/wheels/template-two/temp-3a.png';
import tempTwo_D from '../assets/images/wheels/template-two/temp-4a.png';
import tempTwo_E from '../assets/images/wheels/template-two/temp-5a.png';
import tempTwo_F from '../assets/images/wheels/template-two/temp-6a.png';

//Template Three
import tempThree_A from '../assets/images/wheels/template-three/prize-360.png'
import tempThree_B from '../assets/images/wheels/template-three/prize-100.png'
import tempThree_C from '../assets/images/wheels/template-three/prize-10.png'
import tempThree_D from '../assets/images/wheels/template-three/prize-36.png'
import tempThree_E from '../assets/images/wheels/template-three/no-win.png'
import tempThree_F from '../assets/images/wheels/template-three/grand-prize.png'
import tempThree_G from '../assets/images/wheels/template-three/second-prize.png'
import tempThree_H from '../assets/images/wheels/template-three/third-prize.png'

//Template Foure
import tempFoure_A from '../assets/images/wheels/template-foure/temp-a.png'
import tempFoure_B from '../assets/images/wheels/template-foure/temp-b.png'
import tempFoure_C from '../assets/images/wheels/template-foure/temp-c.png'
import tempFoure_D from '../assets/images/wheels/template-foure/temp-d.png'
import tempFoure_E from '../assets/images/wheels/template-foure/temp-e.png'
import tempFoure_F from '../assets/images/wheels/template-foure/temp-f.png'
import tempFoure_G from '../assets/images/wheels/template-foure/temp-g.png'
import tempFoure_H from '../assets/images/wheels/template-foure/temp-h.png'
import tempFoure_I from '../assets/images/wheels/template-foure/temp-i.png'
import tempFoure_J from '../assets/images/wheels/template-foure/temp-j.png'

export const segmentCount = [
    {
        "label": "4",
        "value": "4"
    },
    {
        "label": "6",
        "value": "6"
    },
    {
        "label": "8",
        "value": "8"
    },
    {
        "label": "10",
        "value": "10"
    },
];

export const segmentTemplateOne = [
    {
        "image": tempOne_B
    },
    {
        "image": tempOne_A
    },
    {
        "image": tempOne_D
    },
    {
        "image": tempOne_C
    },

];

export const segmentTemplateTwo = [
    {
        "image": tempTwo_A
    },
    {
        "image": tempTwo_B
    },
    {
        "image": tempTwo_C
    },
    {
        "image": tempTwo_D
    },
    {
        "image": tempTwo_E
    },
    {
        "image": tempTwo_F
    }
];

export const segmentTemplateThree = [
    {
        "image": tempThree_A
    },
    {
        "image": tempThree_B
    },
    {
        "image": tempThree_C
    },
    {
        "image": tempThree_D
    },
    {
        "image": tempThree_E
    },
    {
        "image": tempThree_F
    },
    {
        "image": tempThree_G
    },
    {
        "image": tempThree_H
    },
];

export const segmentTemplateFoure = [
    {
        "image": tempFoure_A
    },
    {
        "image": tempFoure_B
    },
    {
        "image": tempFoure_C
    },
    {
        "image": tempFoure_D
    },
    {
        "image": tempFoure_E
    },
    {
        "image": tempFoure_F
    },
    {
        "image": tempFoure_G
    },
    {
        "image": tempFoure_H
    },
    {
        "image": tempFoure_I
    },
    {
        "image": tempFoure_J
    },
];
import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import placeholder from '../assets/images/thumbnail.png'
import PrimaryButton from '../components/Buttons/PrimaryButton';
import SecountryBtn from '../components/Buttons/SecountryBtn';
import { useNavigate } from 'react-router-dom';
import { campaignContext } from '../constant/Context';

const Home = ({ homePageImage }) => {

    const navigate = useNavigate();

    const [campaignData, setCampaignData] = useContext(campaignContext);

    const [isTermsmodal, setTermsModal] = useState(false);

    const toggle = () => {
        setTermsModal(!isTermsmodal);
    }

    const navigateTo = () => {
        localStorage.clear();
        navigate('/registration');
    }

    return (
        <>
            <div className='page-container p-0'>
                <div className='home-page-img position-fixed'>

                    <img src={`${homePageImage}`} alt='sample-image' />

                    {/* <div className='pt-80'>
                    <div className='home-img'>
                        <img src={`${placeholder}`} alt='sample-image' />
                    </div>
                </div> */}
                    <div className='begin-btn'>
                        <PrimaryButton btnName={'Tap to Begin'} ClassName="" btnType={'button'} onClick={toggle} />
                    </div>
                </div>
            </div>
            <Modal isOpen={isTermsmodal} className='terms-conditions' toggle={toggle} centered={true} size={'lg'} fullscreen={'md'}>
                <div className='modal-header text-center'>The {campaignData?.campaignName || ""}’s <br />Terms and conditions<br /> </div>
                <ModalBody>
                    <ol>
                        <li> <strong>Campaign Period and Location:</strong><br />
                            The {campaignData?.campaignName || ""} (”Campaign”) is organised by ("Organiser") and it will take place on &lt;Insert Date&gt; ("Campaign Period") at {campaignData?.campaignName || ""} &lt;Insert Address&gt;.</li>
                        <li> <strong>Participation and Eligibility:</strong><br />
                            Participants ("Participants") must purchase items with the '{campaignData?.campaignName || ""}' sticker to be eligible for redemption of lucky draw tickets ("QR ticket"). Participants must present the sticker to the counter in order to redeem the QR ticket.</li>
                        <li> <strong>Lucky Draw Mechanism:</strong><br />
                            The spinning wheel lucky draw will take place exclusively at the digital Kiosk. Any draws conducted outside the Kiosk are considered invalid. Each valid QR ticket allows one (1) spinning chance on the digital Kiosk and cannot be reused. Winning chances are random and not influenced by product value purchased. No limit to spins per user with valid QR tickets.
                        </li>
                        <li> <strong>Email Usage and Consent:</strong><br />
                            Participants must register with their email address at the Kiosk to participate. Email addresses are required for verification, prize redemption, and reminders only. Participants may opt in to receive marketing emails from the organiser.
                        </li>
                        <li> <strong> QR Ticket Responsibilities:
                        </strong><br />
                            The Organiser is not responsible for any unused, expired, damaged, lost, or stolen QR tickets.
                        </li>
                        <li> <strong> Verification and Photo Consent: </strong><br />
                            Upon winning, participants will be notified via email and must present the email for staff verification during prize redemption. A photo of the participant with the staff will be taken as proof of redemption, which may be posted on SpinPro's social media for marketing purpose. Participants have the right to request photo removal from social media. The Organiser is not liable for any legal action arising from photo publication.
                        </li>
                        <li> <strong> Prize Redemption: </strong><br />
                            Prize redemption is on a first-come, first-serve basis. Participants may not be able to choose desired colors if specific colors are out of stock or unavailable for selection. All prizes must be redeemed on same day or else it will be treated as unclaimed prizes and will be forfieted.
                        </li>
                        <li> <strong> Prize Replacement and Acceptance: </strong><br />
                            The Organiser reserves the right to replace prizes with others of similar value in the event of unforeseen issues preventing redemption fulfillment. Participants agree to accept whatever prizes are being replaced by the Organiser.
                        </li>
                        <li> <strong> Disqualification:
                        </strong><br />
                            Participants disagreeing with the terms and conditions cannot participate.
                        </li>
                        <li> <strong>Campaign Closure:
                        </strong><br />
                            All QR tickets must be scanned on same day or else it will be treated as expired tickets, unless the campaign period is extended and officially announced by the Organiser.
                        </li>
                        <li> <strong>Organiser's Decision:
                        </strong><br />
                            The Organiser reserves the right to make the final decision regarding prize claims. The Organiser has the right to refuse prize redemption if misuse, abuse of system loopholes, or fraudulent entry is detected.
                        </li>
                        <li> <strong>Amendments to Terms and Conditions:
                        </strong><br />
                            The Organiser reserves the right to amend terms and conditions without prior notice. Participants should refer to SpinPro's official Facebook for updates.
                        </li>
                        <li> <strong>General Terms:
                        </strong><br />
                            Users failing to read terms on the official Facebook page are not the Organiser's responsibility. The Organiser holds no liability for claims resulting from unread terms.                        </li>
                    </ol>
                    <p className='py-3 gentral-text'>By participating in the {campaignData?.campaignName || ""} Campaign, you agree to abide by these terms and conditions. SpinPro reserves the right to take appropriate action against any violations.
                    </p>

                </ModalBody>
                <ModalFooter>
                    <div className='d-flex gx-5 gap-4 w-100 align-items-center'>
                        <SecountryBtn btnName={'Disagree'} ClassName="" btnType={'button'} onClick={toggle} />
                        <PrimaryButton btnName={'Agree'} ClassName="" btnType={'button'} onClick={navigateTo} />
                    </div>

                </ModalFooter>
            </Modal>
        </>
    );
};

export default Home;
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import { Player } from '@lottiefiles/react-lottie-player';

const EmailVerificationModal = ({ toggle, isOpen, lottiefiles, modalTitle, description, buttonName, PrimaryAction }) => {
    return (
        <>
            <Modal isOpen={isOpen} className='verify-modal' toggle={toggle} centered={true} size={'lg'} fullscreen={'md'}>
                <ModalBody>
                    <div className='text-center verifi-content'>
                        <div className='lottiefiles'>
                            <Player
                                src={lottiefiles}
                                className="player"
                                loop
                                autoplay
                            />
                        </div>
                        <h5 className='form-title pb-2 mb-1'>{modalTitle}</h5>
                        <p>{description}</p>
                        {buttonName ? (
                            <div className='d-flex justify-content-center w-100 pt-80'>
                                <PrimaryButton btnName={buttonName} ClassName="" btnType={'button'} onClick={PrimaryAction} />
                            </div>
                        ) : ""}
                    </div>

                </ModalBody>

            </Modal>
        </>
    );
};

export default EmailVerificationModal;
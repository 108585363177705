import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import SecountryBtn from '../Buttons/SecountryBtn';
import PrimaryButton from '../Buttons/PrimaryButton';
import { obscureEmail } from "../../utils/obscureEmail";
import { spinLaterCountdown } from '../../constant/Countdown';

const SpinlaterModal = ({ toggle, isOpen, description, buttonName, PrimaryAction, closeToggle, closeBtn }) => {
    const [countdown, setCountdown] = useState(spinLaterCountdown);
    const navigate = useNavigate();
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount

    }, []);

    useEffect(() => {

        if (countdown === 0) {
            navigate('/')
        }

    }, [countdown]);

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} className='prize-modal no-win spin-later' centered={true} size={'lg'} fullscreen={'md'}>
                <ModalBody>
                    <div className='text-center verifi-content'>
                        <strong><p>Hi <span className='text-bold'>{obscureEmail(description)}</span> <br /> Are you still there?</p></strong>
                        <div className='d-flex gx-5 flex-column w-100 align-items-center'>
                            <PrimaryButton btnName={buttonName} ClassName="" btnType={'button'} onClick={PrimaryAction} />
                            <SecountryBtn btnName={closeBtn} ClassName="" btnType={'button'} onClick={closeToggle} />
                        </div>
                        <div className='text-center pt-3 invisibl'>
                            <p className='m-0 count-text text-center'>{'You will be directed to the home screen in'} 00:{countdown}</p>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    );
};

export default SpinlaterModal;
import React, { useState, useEffect, useRef } from 'react';

const VideoSlide = ({ index, path, setCurrentSlide, currentSlide, adds }) => {

    const handleVideoEnded = () => {
        setCurrentSlide(prevSlide => (prevSlide + 1) % adds.length);
    };

    return (
        <video
            id={`video-${index}`}
            autoPlay
            muted
            onEnded={handleVideoEnded}
            style={{ width: '1080px', height: '1920px', objectFit: 'cover' }}
            onClick={(e)=> {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <source src={path} type="video/mp4" />
        </video>
    );
};

export default VideoSlide;

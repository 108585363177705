import React, { memo } from 'react';

const PrimaryButton = ({ btnType, btnName, btnIcon, onClick, ClassName, disabled }) => {

    return (
        <>
            <button className={`primary-button common-style ${ClassName}`} type={btnType} onClick={onClick} disabled={disabled}>
                {btnIcon ? <img src={btnIcon} alt='btnicon' /> : ''}
                {btnName}
            </button>
        </>
    );
};

export default memo(PrimaryButton);
import React, { useContext, useEffect, useState } from 'react';
import PrimaryButton from './Buttons/PrimaryButton';
import { apiURL } from '../helpers/URL';
import axios from 'axios';
import PrizeWinModal from './Modals/PrizeWinModal';
import PrizeNoModal from './Modals/PrizeNoModal';
import { useNavigate } from 'react-router-dom';
import SecountryBtn from './Buttons/SecountryBtn';
import SpinlaterModal from './Modals/SpinlaterModal';
import { obscureEmail } from "../utils/obscureEmail";
import Skeleton from "react-loading-skeleton";
import { campaignContext, prizeContext, spinContext } from '../constant/Context';

const SpinWheel = ({ spinTotal, setResetQrCount, setCountdown, countdown, clearIntervalMain, timerflag, startInterval }) => {

    const [campaignData, setCampaignData] = useContext(campaignContext);
    const [spinImage, setSpinImage] = useContext(spinContext);
    const [prizesData, setprizesData] = useContext(prizeContext);

    const navigate = useNavigate();

    const [wheel, setWheel] = useState(null);
    const [spinWheelData, setSpinWheelData] = useState();
    const [openPrizeModal, setOpenPrizeModal] = useState(false);
    const [openNoPrizeModal, setOpenNoPrizeModal] = useState(false);
    const [spinLaterModal, setspinLaterModal] = useState(false);
    const [spinwheelLoader, setSpinwheelLoader] = useState(false);

    let audio = new Audio("/assets/sound/tick.mp3");

    const decodedEmail = localStorage.getItem("reg-site");
    const userEmail = atob(decodedEmail);

    const backToHome = () => {
        navigate('/');
        localStorage.clear();
    };
    const startSpinWheel = async () => {
        try {
            const data = {}
            data['email'] = userEmail
            data['campaign'] = campaignData?._id
            const response = await axios.post(`${apiURL}/adminpanel/qrcode/spin`, data);
            setSpinWheelData(response?.data);

            if (response?.data?.status?.toLowerCase() === "success") {
                if (response?.data?.qrlist?.winningStatus?.toLowerCase() === "won") {
                    prizeModalToggle()
                } else {
                    noPrizeModalToggle()
                }
                setResetQrCount({ spinCount: response?.data?.qrCount });
            }
            stopWheel()
        } catch (error) {
            console.log('Response from the server:', error);
        }
    }

    const stopWheel = () => {
        wheel.stopAnimation();
    }

    //Prize Modal
    const prizeModalToggle = () => {
        setOpenPrizeModal(!openPrizeModal)
    }

    //No Prize Modal
    const noPrizeModalToggle = () => {
        setOpenNoPrizeModal(!openNoPrizeModal)
    };

    const spinlaterToggle = () => {
        setspinLaterModal(true)
    }
    const closespinlatermodal = () => {
        setspinLaterModal(false)
        startInterval();
    }
    const closeModal = () => {
        startInterval();
        setOpenNoPrizeModal(false)
        setOpenPrizeModal(false)
        navigate('/game-spin');
    }
    useEffect(() => {

        const segments = spinImage?.path?.map((item, index) => {
            return ({
                'image': item?.image,
                "text": spinImage?.value[index]?.productCode || ""
            })
        });

        const newWheel = new window.Winwheel({
            numSegments: spinImage?.path?.length,
            outerRadius: 150,
            textFontSize: 30,
            textAlignment: 'inner',
            textMargin: '120',
            txtFontFamily: 'Montserrat',
            textFillStyle: '#fff',
            canvasId: "myCanvas",
            drawMode: "segmentImage",
            segments: segments,
            drawText: true,
            animation: {
                type: "custom",
                duration: 8,
                propertyName: "rotationAngle",
            },
        });
        setWheel(newWheel);
    }, [spinImage]);

    const onSpinComplete = (indicatedSegment) => {
        // navigate("result", { state: indicatedSegment.text });
    };

    const playSound = () => {
        audio.pause();
        audio.currentTime = 0;
        audio.play();
    };

    const handleSpin = () => {
        clearIntervalMain();
        wheel.animation.propertyValue = (8 * 360) + probability();
        wheel.startAnimation();
        setTimeout(() => {
            startSpinWheel();
        }, 3000)
    };

    const probability = () => {
        wheel.rotationAngle = 0;
        let win_pro = Math.random() * 100;
        let check_per = 0;
        let propertyValue = 0;
        let segments = wheel.segments
            .filter((n) => n)
            .sort((a, b) => a.probability - b.probability);
        segments.forEach((segment) => {
            if (segment) {
                if (win_pro > check_per && win_pro <= check_per + segment.probability) {
                    propertyValue =
                        360 -
                        (segment.startAngle + (segment.endAngle - segment.startAngle) / 2);
                }
                check_per = check_per + segment.probability;
            }
        });
        return propertyValue;
    };



    useEffect(() => {
        if (countdown === 0 && timerflag === 'start') {
            spinlaterToggle();
        }
    }, [countdown]);

    const showPrizeImage = () => {
        const path = prizesData?.filter((item) => item?._id === spinWheelData?.img?._id);
        return path && path?.length > 0 && path[0]?.prizeImg;
    }

    return (
        <>
            <div className={`wheel-spiner ${spinTotal?.spinCount === 0 ? 'disabled' : ''}`}>
                <div id="myCanvasContainer" style={{ width: "100%", height: "100%" }}>
                    {spinwheelLoader ?
                        <div className='mt-3 mb-4'>
                            <Skeleton baseColor="#cccccc" className='spinner-skeleton' height={"800px"} width={'800px'} borderRadius={'50%'} />
                        </div> :
                        <canvas id="myCanvas" width="840" height="840"></canvas>
                    }
                </div>
            </div>
            <div className='text-center'>
                <PrimaryButton btnName={'Spin!'} ClassName="" btnType={'submit'} onClick={handleSpin} disabled={spinTotal?.spinCount === 0 ? true : false} />
            </div>
            <div className='mt-4 text-center spin-later'>
                <SecountryBtn
                    btnName={"I’ll Spin Later"}
                    ClassName=""
                    btnType={"button"}
                    onClick={backToHome}
                />
                <p className='mt-4'>If you are in a rush, you may come back later.<br />
                    Just login with the same email address.</p>
            </div>
            <p className='m-0 count-text text-center d-none'>{'You will be directed to the home screen in'} 00:{countdown}</p>

            <PrizeWinModal
                toggle={prizeModalToggle}
                isOpen={openPrizeModal}
                prizeImage={showPrizeImage()}
                modalTitle={`${"CONGRATULATIONS!"}`}
                description={`${`You have won ${spinWheelData?.qrlist?.productId}! We have sent you the prize redemption email to ${spinWheelData?.qrlist?.email && obscureEmail(spinWheelData?.qrlist?.email)}. Kindly present the email to our staff for prize collection.`}`}
                tryAgin={`${spinTotal?.spinCount === 0 ? `${``}` : `${`You still have ${spinWheelData?.qrCount} spin(s) left. Try again to win more prizes!`}`}`}
                buttonName={`${spinTotal?.spinCount === 0 ? `${`Back to Home`}` : `${`Try Again (${spinWheelData?.qrCount} spin left)`}`}`}
                PrimaryAction={spinTotal?.spinCount === 0 ? backToHome : closeModal}
            />

            {/* No Prize Modal */}
            <PrizeNoModal
                toggle={noPrizeModalToggle}
                prizeImage={showPrizeImage()}
                isOpen={openNoPrizeModal}
                modalTitle={`${"OPPS…!"}`}
                description={`${spinTotal?.spinCount === 0 ? `${`You did not win any prize this time and you have no more spinning chance left.`}` : `${`You did not win any prize this time but you still have ${spinWheelData?.qrCount} spin(s) left.`}`}`}
                tryAgin={`${spinTotal?.spinCount === 0 ? `${`Thank you for your participation.`}` : `${`Try again for a better luck!`}`}`}
                buttonName={`${spinTotal?.spinCount === 0 ? `${`Back to Home`}` : `${`Try Again (${spinWheelData?.qrCount || 0} spin left)`}`}`}
                PrimaryAction={spinTotal?.spinCount === 0 ? backToHome : closeModal}
            />

            {spinLaterModal === true && <SpinlaterModal
                toggle={spinlaterToggle}
                isOpen={spinLaterModal}
                description={userEmail}
                buttonName={'Yes, Continue'}
                closeBtn={'Back to Home'}
                closeToggle={backToHome}
                PrimaryAction={closespinlatermodal}
            />}
        </>
    );
};

export default SpinWheel;
import React, { useState, useEffect } from 'react';

const CustomSlider = ({ slides, currentSlide, adds, setCurrentSlide }) => {
    
    useEffect(() => {
        if (adds[currentSlide]?.fileType === "image") {
            setTimeout(() => {
                setCurrentSlide(prevSlide => (prevSlide + 1) % adds.length);
            }, [10000])
        } else {
            const currentSlideVideo = document.getElementById(`video-${currentSlide}`)
            currentSlideVideo.currentTime = 0;
            currentSlideVideo.play();
        }
    }, [currentSlide, adds]);

    return (
        <div className="custom-slider">
            <div className="slider-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`slide ${index === currentSlide ? 'active' : ''}`}
                    >
                        {slide}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomSlider;
